import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'
import SbEditable from 'storyblok-react'
import Box from '@material-ui/core/Box'
import Img from '@system/img'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'

const useStyles = makeStyles((theme) => ({
  assetGraphic: {
    position: 'relative',
    [ACC_THEME.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  assetButton: {
    position: 'absolute',
    right: '3%',
    top: '40%',
    [ACC_THEME.breakpoints.down('xs')]: {
      right: '0',
      top: '67%',
    },
  },
}))

const AssetDownloadBanner = (props) => {
  const classes = useStyles()
  const { assetGraphic, assetDownloadButton } = props.blok

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.assetGraphic}>
        {!!assetGraphic && (
          <Img src={assetGraphic.filename} alt={assetGraphic.name} />
        )}
        {!!assetDownloadButton && (
          <Box className={classes.assetButton}>
            {renderBloks(assetDownloadButton)}
          </Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default AssetDownloadBanner
